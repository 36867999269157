import { Controller } from "stimulus"
import { getRelativePosition, getValueForPixel } from 'chart.js/helpers';

export default class extends Controller {

  static targets = [ "monthChart", "monthIncomes", "monthExpenses", "monthBalance", "ordersChart", "invoicesChart", "warehouseChart" ]

  static values = {
    currency: String,
    currencyFormat: String,
    currencyAbbr: String
  }

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }
  }

  connect() {
    var that = this;
    this.loadSummaryChart();
    this.loadOrdersChart();
    this.loadInvoicesChart();
    if(this.hasWarehouseChartTarget) {
      this.loadWarehouseChart();
    }
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
    $('#loading').fadeOut(200);
  }

  currenciesAbbr(currency) {
    if(this.currencyAbbrValue == 'iso4217') {
      return currency.toUpperCase();
    } else {
      return I18n.t(`currencies.abbr.${currency}`);
    }
  }

  currenciesFormat(value, currency) {
    if(this.currencyFormatValue == '%u %n') {
      return `${this.currenciesAbbr(currency)} ${value}`
    } else {
      return `${value} ${this.currenciesAbbr(currency)}`
    }
  }

  initTables() {

  }

  loadSummaryChart() {
    if(!this.hasMonthChartTarget) {
      return
    }

    let that = this;
    Rails.ajax({
      url: `/${this.locale}/start/stats/accounting`,
      type: "get",
      data: "",
      success: function(data) {
        let inc = data.datasets[0].data[9];
        let exp = data.datasets[1].data[9];
        let bal = inc-exp;
        that.monthIncomesTarget.textContent = that.currenciesFormat(inc.toFixed(2), that.currencyValue);
        that.monthExpensesTarget.textContent = that.currenciesFormat(exp.toFixed(2), that.currencyValue);
        that.monthBalanceTarget.textContent = that.currenciesFormat(bal.toFixed(2), that.currencyValue);
        if(bal < 0) {
          that.monthBalanceTarget.classList.add('text-danger')
        } else {
          that.monthBalanceTarget.classList.remove('text-danger')
        }

        const accountingChart = new Chart(that.monthChartTarget, {
            data: data,
            options: {
              onResize: function() {

              },
              barValueSpacing: 20,
              plugins: {
                title: {
                  text: `${I18n.t('accounting.profit')} / ${I18n.t('accounting.lost')} ${moment().format("Y")}`,
                  display: true,
                  font: {
                    size: 16
                  }
                },
                legend: {
                  labels: {
                    usePointStyle: true,
                  }
                },
                tooltip: {
                  callbacks: {
                    title: function(tooltipItem, d) {
                      return tooltipItem[0].label;
                    },
                    label: function(tooltipItem, d) {
                      return ' '+that.currenciesFormat(tooltipItem.formattedValue, that.currencyValue);
                    },
                  }
                }
              },
              scales: {
                y: {
                  ticks: {
                    callback: function(value, index, values) {
                      return that.currenciesFormat(value, that.currencyValue);
                    }
                  }
                }
              }
            }
        });
      },
      error: function(data) {}
    });
  }

  loadOrdersChart() {
    if(!this.hasOrdersChartTarget) {
      return
    }

    let that = this;
    Rails.ajax({
      url: `/${this.locale}/start/stats/orders`,
      type: "get",
      data: "",
      success: function(data) {
        const ordersChart = new Chart(that.ordersChartTarget, {
          data: data,
          type: 'doughnut',
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: 'left',
                onHover: (event, legendItem, legend) => {
                  event.native.target.style.cursor = 'pointer';
                },
                onLeave: (event, legendItem, legend) => {
                  event.native.target.style.cursor = 'auto';
                },
                onClick: (event, legendItem, legend) => {
                  let label = legendItem.text.split('|')[1].trim();
                  Cookies.set(`filter_orders`, label);
                  window.location = `/${that.locale}/orders`
                },
                labels: {
                  usePointStyle: true,
                  generateLabels: function(chart) {
                    const datasets = chart.data.datasets;
                    return datasets[0].data.map((data, i) => ({
                      text: `${data} | ${chart.data.labels[i]}`,
                      fillStyle: datasets[0].backgroundColor[i],
                    }))
                  }
                }
              }
            },
            onHover: (event, item, legend) => {
              if(item.length > 0) {
                event.native.target.style.cursor = 'pointer';
              } else {
                event.native.target.style.cursor = 'auto';
              }
            },
            onLeave: (event, item, legend) => {
              event.native.target.style.cursor = 'auto';
            },
            onClick: (e, activeEls) => {
              if(activeEls.length > 0) {
                let dataIndex = activeEls[0].index;
                let label = e.chart.data.labels[dataIndex];
                Cookies.set(`filter_orders`, label);
                window.location = `/${that.locale}/orders`
              }
            }
          }
        });
      },
      error: function(data) {}
    });
  }

  loadInvoicesChart() {
    if(!this.hasInvoicesChartTarget) {
      return
    }

    let that = this;
    Rails.ajax({
      url: `/${this.locale}/start/stats/invoices`,
      type: "get",
      data: "",
      success: function(data) {
        const invoicesChart = new Chart(that.invoicesChartTarget, {
          data: data,
          type: 'doughnut',
          options: {
            responsive: true,
            plugins: {
              tooltips: {
                 mode: 'dataset'
              },
              legend: {
                position: 'left',
                onHover: (event, legendItem, legend) => {
                  event.native.target.style.cursor = 'pointer';
                },
                onLeave: (event, legendItem, legend) => {
                  event.native.target.style.cursor = 'auto';
                },
                onClick: (event, legendItem, legend) => {
                  let label = legendItem.text.split('|')[1].trim();
                  Cookies.set(`filter_documents_invoices`, label);
                  window.location = `/${that.locale}/documents/invoices`
                },
                labels: {
                  usePointStyle: true,
                  generateLabels: function(chart) {
                    const datasets = chart.data.datasets;
                    return datasets[0].data.map((data, i) => ({
                      text: `${data} | ${chart.data.labels[i]}`,
                      fillStyle: datasets[0].backgroundColor[i],
                    }))
                  }
                }
              }
            },
            onHover: (event, item, legend) => {
              if(item.length > 0) {
                event.native.target.style.cursor = 'pointer';
              } else {
                event.native.target.style.cursor = 'auto';
              }
            },
            onLeave: (event, item, legend) => {
              event.native.target.style.cursor = 'auto';
            },
            onClick: (e, activeEls) => {
              if(activeEls.length > 0) {
                let dataIndex = activeEls[0].index;
                let label = e.chart.data.labels[dataIndex];
                Cookies.set(`filter_documents_invoices`, label);
                window.location = `/${that.locale}/documents/invoices`
              }
            }
          }
        });
      },
      error: function(data) {}
    });
  }

  loadWarehouseChart() {
    if(!this.hasWarehouseChartTarget) {
      return
    }

    let that = this;
    Rails.ajax({
      url: `/${this.locale}/start/stats/warehouses`,
      type: "get",
      data: "",
      success: function(data) {
        const warehouseChart = new Chart(that.warehouseChartTarget, {
          data: data,
          type: 'doughnut',
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: 'left',
                onHover: (event, legendItem, legend) => {
                  event.native.target.style.cursor = 'pointer';
                },
                onLeave: (event, legendItem, legend) => {
                  event.native.target.style.cursor = 'auto';
                },
                onClick: (event, legendItem, legend) => {
                  let label = legendItem.text.split('|')[1].trim();
                  if(label == I18n.t('item.states.low_stock')) {
                    window.location = `/${that.locale}/warehouse/lowstock`
                  } else {
                    Cookies.set(`filter_items`, label);
                    window.location = `/${that.locale}/items`
                  }
                },
                labels: {
                  usePointStyle: true,
                  generateLabels: function(chart) {
                    const datasets = chart.data.datasets;
                    return datasets[0].data.map((data, i) => ({
                      text: `${data} | ${chart.data.labels[i]}`,
                      fillStyle: datasets[0].backgroundColor[i],
                    }))
                  }
                }
              }
            },
            onHover: (event, item, legend) => {
              if(item.length > 0) {
                event.native.target.style.cursor = 'pointer';
              } else {
                event.native.target.style.cursor = 'auto';
              }
            },
            onLeave: (event, item, legend) => {
              event.native.target.style.cursor = 'auto';
            },
            onClick: (e, activeEls) => {
              if(activeEls.length > 0) {
                let dataIndex = activeEls[0].index;
                let label = e.chart.data.labels[dataIndex];
                if(label == I18n.t('item.states.low_stock')) {
                  window.location = `/${that.locale}/warehouse/lowstock`
                } else {
                  Cookies.set(`filter_items`, label);
                  window.location = `/${that.locale}/items`
                }
              }
            }
          }
        });
      },
      error: function(data) {}
    });
  }
}
