import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "filter", "col_price", "col_price_with_vat", "warehouseChart" ]

  static values = {
    currency: String,
    currencyFormat: String,
    currencyAbbr: String
  }

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }
    if(this.element.getAttribute("data-page")) {
      this.page = this.element.getAttribute("data-page");
    }
    if(this.element.getAttribute("data-warehouse")) {
      this.warehouse = this.element.getAttribute("data-warehouse");
    }
    if(this.element.getAttribute("data-warehouses")) {
      this.warehouses = this.element.getAttribute("data-warehouses");
    }
    if(this.element.getAttribute("data-prices-with-vat")) {
      this.pricesWithVAT = this.element.getAttribute("data-prices-with-vat");
    }
    if(this.element.getAttribute("data-filter")) {
      this.filter = this.element.getAttribute("data-filter");
    }
  }

  connect() {
    $(".selectpicker").selectpicker({width: '100%'});

    let filter;
    if(this.warehouse) {
      filter = Cookies.get(`filter_warehouse_${this.warehouse}`);
    }

    if(filter && this.hasFilterTarget) {
      this.filterTarget.value = filter;
      this.filterItems();
    }

    if(this.warehouse) {
      this.initTableFunctions();
      this.initData();
    }

    if(this.filter) {
      if(this.filter == 'lowstock' || this.filter == 'nextexpiration') {
        this.initTableFunctions();
        this.initData();
      }
    }

    if(this.page == 'index') {
      this.initCharts();
    }

    $('#loading').fadeOut(200);
  }

  filterItems() {
    if(this.warehouse) {
      Cookies.set(`filter_warehouse_${this.warehouse}`, this.filterTarget.value.toLowerCase());
    }
  }

  clearFilter() {
    this.filterTarget.value = '';

    Cookies.expire(`filter_warehouse_${this.warehouse}`);

    this.filterItems();
    $("#orders").bootstrapTable('resetSearch', '');
    this.filterTarget.focus();
    this.filterTarget.blur();
  }

  currenciesAbbr(currency) {
    if(this.currencyAbbrValue == 'iso4217') {
      return currency.toUpperCase();
    } else {
      return I18n.t(`currencies.abbr.${currency}`);
    }
  }

  currenciesFormat(value, currency) {
    if(this.currencyFormatValue == '%u %n') {
      return `${this.currenciesAbbr(currency)} ${value}`
    } else {
      return `${value} ${this.currenciesAbbr(currency)}`
    }
  }

  togglePriceColumn() {
    if(event.target.checked) {
      this.col_priceTargets.forEach( (col) => {
        col.classList.add('d-none');
      });
      this.col_price_with_vatTargets.forEach( (col) => {
        col.classList.remove('d-none');
      })
    } else {
      this.col_price_with_vatTargets.forEach( (col) => {
        col.classList.add('d-none');
      });
      this.col_priceTargets.forEach( (col) => {
        col.classList.remove('d-none');
      })
    }

    Rails.ajax({
      url: `/${this.locale}/subscription/config`,
      type: "post",
      data: `parameter=items_list_price_with_vat&value=${event.target.checked}`,
      success: function(data) {},
      error: function(data) {}
    });
  }

  loadStockQuantities() {
    let btn = event.target;
    let div = btn.parentNode;
    let uuid = btn.dataset.uuid;
    let table = div.querySelector('table');
    if(btn.dataset.loaded == 'true') {
      table.innerHTML = '';
      btn.dataset.loaded = 'false';
    } else {
      Rails.ajax({
        url: `/${this.locale}/item/${uuid}/stock`,
        type: "get",
        success: function(data) {
          table.innerHTML = '';
          data.forEach((stock) => {
            table.innerHTML += `<tr><td class="pb-0 text-nowrap">${stock.name}:</td><td class="pb-0"> ${stock.stock ? stock.stock : '-'}</td></tr>`;
          });
          btn.dataset.loaded = 'true';
        },
        error: function(data) {}
      });
    }
  }

  deleteItemConfirm() {
    let warehousesController = this;
    var uuid = event.target.dataset.uuid;
    var msg = `<span class="lead">${event.target.dataset.name}</span><br><span class="small text-muted">${event.target.dataset.number}</span>`;
    bootbox.dialog({
      message: `${I18n.t('item.confirm.delete')}<br><br>${msg}`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.delete'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${warehousesController.locale}/item/${uuid}/delete`,
              type: "delete",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
    	      bootbox.hideAll();
          }
        }
      }
    });
  }

  initCharts() {
    let that = this;
    this.warehouseChartTargets.forEach((chart, i) => {
      Rails.ajax({
        url: `/${that.locale}/warehouses/stats/${chart.dataset.uuid}`,
        type: "get",
        data: "",
        success: function(data) {
          const accountingChart = new Chart(chart, {
            data: data,
            type: 'doughnut',
            options: {
              onResize: function() {

              },
              plugins: {
                tooltip: {
                  callbacks: {
                    title: function(tooltipItem, d) {
                      return tooltipItem[0].label;
                    },
                    label: function(tooltipItem, d) {
                      return [` ${I18n.t('item.items')}: ${tooltipItem.formattedValue}`, ` ${I18n.t('warehouse.stock_quantity')}: ${data.datasets[0].stocks[tooltipItem.dataIndex]}`];
                    },
                  }
                },
                legend: {
                  position: 'left',
                  onHover: (event, legendItem, legend) => {
                    event.native.target.style.cursor = 'pointer';
                  },
                  onLeave: (event, legendItem, legend) => {
                    event.native.target.style.cursor = 'auto';
                  },
                  onClick: (event, legendItem, legend) => {
                    let label = legendItem.text.split('|')[1].trim();
                    Cookies.set(`filter_warehouse_${chart.dataset.uuid}`, label);
                    window.location = `/${that.locale}/warehouse/${chart.dataset.uuid}`
                  },
                  labels: {
                    usePointStyle: true,
                    generateLabels: function(chart) {
                      const datasets = chart.data.datasets;
                      return datasets[0].data.map((data, i) => ({
                        text: `${chart.data.labels[i]}: ${data} (${datasets[0].stocks[i]})`,
                        fillStyle: datasets[0].backgroundColor[i],
                      }))
                    }
                  }
                }
              },
              onHover: (event, item, legend) => {
                if(item.length > 0) {
                  event.native.target.style.cursor = 'pointer';
                } else {
                  event.native.target.style.cursor = 'auto';
                }
              },
              onLeave: (event, item, legend) => {
                event.native.target.style.cursor = 'auto';
              },
              onClick: (e, activeEls) => {
                if(activeEls.length > 0) {
                  let dataIndex = activeEls[0].index;
                  let label = e.chart.data.labels[dataIndex];
                  Cookies.set(`filter_warehouse_${chart.dataset.uuid}`, label);
                  window.location = `/${that.locale}/warehouse/${chart.dataset.uuid}`
                }
              }
            }
          });
        },
        error: function(data) {}
      });
    });
  }

  initData() {
    let warehousesController = this;
    let table;
    if(this.warehouse == 'movements') {
      table = $("#movements");

      $.fn.bootstrapTable.locales[this.locale]['formatShowingRows'] = function (from, to, total) {
        return I18n.t('warehouse.movements_pagination', {from: from, to: to, total: total});
      }
      $.fn.bootstrapTable.locales[this.locale]['formatRecordsPerPage'] = function (count) {
        return I18n.t('warehouse.movements_per_page', {count: count});
      }
    } else {
      table = $("#items");

      $.fn.bootstrapTable.locales[this.locale]['formatShowingRows'] = function (from, to, total) {
        return I18n.t('item.pagination', {from: from, to: to, total: total});
      }
      $.fn.bootstrapTable.locales[this.locale]['formatRecordsPerPage'] = function (count) {
        return I18n.t('item.per_page', {count: count});
      }
    }

    table.bootstrapTable({
      url: `${window.location.href}`,
      locale: this.locale,
      minHeight: '400',
      classes: 'table',
      search: true,
      pagination: true,
      paginationLoop: false,
      pageSize: 100,
      pageList: [50, 100, 250, 'All'],
      searchSelector: '#filter_search',
      searchText: this.filterTarget.value,
      searchTimeOut: 500,
      loadingFontSize: '1rem',
    });

    table.on('load-success.bs.table', () => {
      if(this.filterTarget.value != '') {
        this.filterTarget.focus();
        this.filterTarget.blur();
      }

      //Disable show/hide toggle for actions column
      let chks = document.querySelector('.fixed-table-toolbar').querySelectorAll('input[type=checkbox]');
      if(chks && chks.length > 0) {
        chks[chks.length-1].parentNode.classList.add('d-none');
      }
    });

    table.on("column-switch.bs.table", function() {
      let cols = JSON.stringify(table.bootstrapTable('getVisibleColumns').map(function (it) {
        return it.field
      }))
      Rails.ajax({
        url: `/${warehousesController.locale}/subscription/config`,
        type: "post",
        data: `parameter=warehouses_list_columns&value=${cols}`,
        success: function(data) {},
        error: function(data) {}
      });
    });

    table.on('reset-view.bs.table', () => {
      $('[data-bs-toggle="popover"]').popover();
    });
  }

  loadNextData(offset) {
    let table;
    if(this.warehouse == 'movements') {
      table = $("#movements");
    } else {
      table = $("#items");
    }

    let warehousesController = this;
    Rails.ajax({
      url: `${window.location.href}?limit=500&offset=${offset}`,
      type: "get",
      data: "",
      success: function(data) {
        table.bootstrapTable('append', data)
        if(data.length == 500) {
          warehousesController.loadNextData(offset+500);
        }
      },
      error: function(data) {
        console.log("Error loading orders");
      }
    });
  }

  initTableFunctions() {
    I18n.locale = this.locale;
    let locale = this.locale;
    let warehouses = this.warehouses;
    let that = this;

    /*
      Warehouse
    */
    window.itemNameFormatter = function(name, item) {
      let str = `<a href="/${locale}/item/${item.uuid}">${name}</a>`
      if(item.statusname) {
        if(item.status_subscription_id) {
          str += `<span class="badge bg-light text-dark float-right small">${item.statusname}</span>`
        } else {
          str += `<span class="badge bg-light text-dark float-right small">${I18n.t(`item.states.${item.statusname}`)}</span>`
        }
      }
      return str
    }
    window.itemWarehouseFormatter = function(warehouse, item) {
      if(item.warehouse_data && item.warehouse_data.color) {
        return `<a href="/${locale}/warehouse/${item.warehouse_uuid}"><span style="color:${item.warehouse_data.color}">${warehouse}</span></a>`
      } else {
        return `<a href="/${locale}/warehouse/${item.warehouse_uuid}">${warehouse}</a>`
      }
    }

    window.itemNumberFormatter = function(number, item) {
      let str = '';
      if(number) {
        str = number;
      }
      if(item.ean && item.ean != '') {
        str += `
          <button type="button" class="btn btn-oultine-info btn-sm" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="focus" data-bs-content="${I18n.t('item.ean_abbr')}: ${item.ean}">
            <i class="fas fa-barcode"></i>
          </button>
        `
      }
      return str
    }
    window.itemPriceFormatter = function(price, item) {
      if(item.price_with_discount) {
        return `
          <small class="strikethrough">
            ${that.currenciesFormat(item.price.toFixed(2), item.currency)}
          </small><br>
          <span class="text-danger">
            ${that.currenciesFormat(item.price_with_discount.toFixed(2), item.currency)}
          </span>
        `
      } else if(item.price) {
        return `
          ${that.currenciesFormat(item.price.toFixed(2), item.currency)}
        `
      }
    }
    window.itemPriceWitVatFormatter = function(price, item) {
      if(item.price_with_discount) {
        return `
          <small class="strikethrough">
            ${that.currenciesFormat((item.price*(1+(item.vat/100))).toFixed(2), item.currency)};
          </small><br>
          <span class="text-danger">
            ${that.currenciesFormat((item.price_with_discount*(1+(item.vat/100))).toFixed(2), item.currency)}
          </span>
        `
      } else if(item.price_with_vat) {
        return `
          ${that.currenciesFormat(item.price_with_vat.toFixed(2), item.currency)}
        `
      }
    }
    window.itemStockFormatter = function(stock, item) {
      if(item.with_stock) {
        return stock
      } else {
        return ''
      }
    }
    window.itemTotalStockFormatter = function(stock_total, item) {
      if(item.with_stock) {
        if(warehouses > 1) {
          return `
            <button type="button" class="btn btn-outline-light btn-sm text-dark" data-action="click->warehouses#loadStockQuantities" data-uuid="${item.uuid}" data-loaded="false">
              ${stock_total}
            </button>
            <table class="small table-borderless table-sm"></table>
          `
        } else {
          return stock_total
        }
      } else {
        return ''
      }
    }
    window.itemReservedFormatter = function(reserved, item) {
      if(item.with_stock && item.reserved && item.reserved > 0) {
        return reserved
      } else {
        return ''
      }
    }
    window.itemAvailableFormatter = function(available, item) {
      if(item.with_stock) {
        return item.availability
      } else {
        return ''
      }
    }
    window.itemActionsFormatter = function(i, item) {
      return `
        <div class="btn-group" role="group">
          <a class="btn btn-outline-secondary" href="/${locale}/item/${item.uuid}/edit">
            <i class="fas fa-edit"></i>
          </a>
          <button type="button" class="btn btn-outline-danger" data-uuid="${item.uuid}" data-name="${item.i18n_name}" data-number="${item.number}" data-action="click->warehouses#deleteItemConfirm">
            <i class="fas fa-trash"></i>
          </button>
        </div>
      `
    }


    /*
      Movements
    */
    window.referenceFormatter = function(reference, row) {
      let link = `/${locale}/order/${row.uuid}`;
      return `<a href="${link}">${reference}</a>`
    }

    window.warehouseFormatter = function(warehouse, row) {
      return `<font color="${row.warehouse_color}">${warehouse}</font>`
    }

    window.dateFormatter = function(date) {
      return I18n.l("date.formats.default", date);
    }

    window.itemFormatter = function(item, row) {
      return `
        ${item}
        <small>
          <b>${row.item_number}</b>
        </small>
      `
    }

    window.stockFormatter = function(stock) {
      if(stock && stock < 0) {
        return `<span class="text-danger">${stock}</span>`
      } else {
        return stock
      }
    }

    window.changeFormatter = function(change) {
      if(change > 0) {
        return `<h5 class="m-0"><span class="badge bg-success smaller">+${change}</span></h5>`
      } else {
        return `<h5 class="m-0"><span class="badge bg-secondary smaller">${change}</span></h5>`
      }
    }

    window.contactFormatter = function(contact, row) {
      if(row.orderaddress) {
        let arr = row.orderaddress.split("\n")
        return arr[0]
      } else if(row.purchaseaddress) {
        let arr = row.purchaseaddress.split("\n")
        return arr[0]
      } else {
        return ''
      }
    }

    window.referenceFormatter = function(reference, row) {
      if(row.order) {
        return `<a title="${I18n.t('order.order')} ${row.orderref}" href="/${locale}/order/${row.orderuuid}"><i class="fas fa-file-upload fa-fw"></i>&nbsp;${row.orderref}</a>`
      }
      if(row.purchase) {
        return `<a title="${I18n.t('purchase.purchase')} ${row.purchaseref}" href="/${locale}/purchase/${row.purchaseuuid}"><i class="fas fa-file-download fa-fw"></i>&nbsp;${row.purchaseref}</a>`
      }
      if(row.sale) {
        return `<i class="fas fa-cash-register"></i> ${row.salesref}`
      }
      return ''
    }

    window.changedByFormatter = function(change, row) {
      if(row.firstname || row.lastname)
        return [row.firstname, row.lastname].join(' ')
      else
        return 'System'
      end
    }

    window.noteFormatter = function(note) {
      if(note && note != '') {
        return `
          <button type="button" class="btn btn-light bg-white" data-bs-toggle="popover" data-bs-trigger="focus" data-bs-placement="top" data-bs-content="${note}">
            <i class="far fa-comment"></i>
          </button>
        `
      } else {
        return ''
      }
    }
  }

}
